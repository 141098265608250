import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Form from "./Form";
import Summary from "./Summary";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import LocationCity from "@mui/icons-material/LocationCity";

import classes from "./Terminal.module.css";
import { Typography } from "@mui/material";
import { round } from "../../utils/marks";

const Terminal = (props) => {
  const [flightMark, setFlightMark] = useState(0);
  const [landingMark, setLandingMark] = useState(0);
  const [outrunMark, setOutrunMark] = useState(0);
  let ref = useRef();

  const timestamps = [
    560, 614, 675, 729, 784, 841, 901, 961, 1021, 1087, 1154, 1302, 1362, 1416,
    1498, 1561, 1625, 1680, 1740, 1805, 1865, 1925, 2138, 2195, 2255, 2321,
    2386, 2442, 2522, 2580, 2648, 2716, 2790, 2940, 3009, 3076, 3145, 3206,
    3273, 3333, 3402, 3474, 3546, 3605,
  ];

  const { onClose, selectedValue, video_id, open, result, userMark, index } = props;

  const [, forceUpdate] = useState(0);

  const [alignment, setAlignment] = React.useState("no-video");

  React.useEffect(() => {
    if (video_id) {
      setAlignment("row");
    }
  }, [video_id]);

  useEffect(() => {
    console.log(result);
    if (result) {
      const t = timestamps[index];
      if (t) {
        ref.currentTime = t;
      }
    }
  }, [result && result.bib]);

  const loadFirstVideo = () => {
    console.log("video loaded", index);
    if (timestamps[index]) {
      ref.currentTime = timestamps[index];
    }
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const reset = () => {
    setFlightMark(0);
    setLandingMark(0);
    setOutrunMark(0);
  };

  const handleClose = () => {
    reset();
    onClose(selectedValue);
  };

  const handleMark = (part, mark) => {
    switch (part) {
      case "flight":
        setFlightMark(mark);
        break;
      case "landing":
        setLandingMark(mark);
        break;
      case "outrun":
        setOutrunMark(mark);
        break;
      default:
        console.error("Error");
    }
  };

  const saveMark = () => {
    const { result } = props;
    const total = 20 + flightMark + landingMark + outrunMark;
    props.onSave({
      mark: {
        flight: -flightMark,
        outrun: -outrunMark,
        landing: -landingMark,
        result_id: result.id,
        total,
      },
    });
  };

  const updateMark = () => {
    const { result, userMark } = props;
    const total = 20 + flightMark + landingMark + outrunMark;
    props.onUpdate({
      id: userMark.id,
      mark: {
        flight: -flightMark,
        outrun: -outrunMark,
        landing: -landingMark,
        result_id: result.id,
        total,
      },
    });
  };

  if (!props.result) {
    return null;
  }

  const handleNextAthlete = () => {
    reset();
    props.onNextAthlete();
  };

  const nextButton = () => {
    if (props.next) {
      return (
        <Button
          variant="contained"
          onClick={handleNextAthlete}
          color="primary"
          autoFocus
        >
          Next: {props.next && props.next.name}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          onClick={props.onComplete}
          color="secondary"
          autoFocus
        >
          Complete Round
        </Button>
      );
    }
  };

  // const handleIframe = (e) => {
  //   // forceUpdate(n => !n);
  //   console.log("---");
  // };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={alignment === "row" ? "xl" : "md"}
      onClose={handleClose}
      aria-labelledby="virtual-judge-dialog"
      open={open}
    >
      <div
        className={[[classes.layout], [classes[`layout-${alignment}`]]].join(
          " "
        )}
      >
        {alignment !== "no-video" && (
          <div className={classes.video}>
            {/* <iframe
              ref={(el) => ref = el}
              title="video"
              onLoad={handleIframe}
              style={{ border: 'none' }}
              src={`https://drive.google.com/file/d/${video_id}/preview?t=${t}`}
            /> */}

            <video
              width="100%"
              autoPlay
              controls
              ref={(el) => (ref = el)}
              onLoadedData={loadFirstVideo}
            >
              <source
                src="https://virtual-judge-videos.s3.eu-west-1.amazonaws.com/RA_FIS_SJU_20241006_WMHS140_KLI_lowres.mp4"
                type="video/mp4"
              />
            </video>

            {/* <button onClick={handleIframe}>Move</button> */}
          </div>
        )}
        <div className={classes.terminal}>
          <DialogTitle id="simple-dialog-title">
            <Box display="flex">
              <Typography variant="h5" color="primary">
                <Badge
                  badgeContent={`${props.result.bib}`}
                  color="primary"
                  sx={{ marginRight: 2 }}
                >
                  <Avatar src={props.jumper && props.jumper.photo} />
                </Badge>
                <span>
                  {props.result.name} ({props.result.nation})
                </span>
                <Tooltip
                  title={
                    <span style={{ fontSize: 18 }}>
                      {props.competition.date} -{" "}
                      {props.competition.hill.location.name} -{" "}
                      {round(props.competition.round)}
                    </span>
                  }
                  placement="top"
                >
                  <LocationCity
                    sx={{
                      fontSize: 32,
                      position: "absolute",
                      top: 24,
                      right: 24,
                    }}
                  />
                </Tooltip>
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            {props.summary ? (
              <Summary
                average={props.result.mark_average}
                marks={props.result.marks}
                experts_mark={props.result.experts_mark}
                flight={flightMark}
                landing={landingMark}
                outrun={outrunMark}
              />
            ) : (
              <Form
                handleMark={handleMark}
                flightMark={flightMark}
                landingMark={landingMark}
                outrunMark={outrunMark}
              />
            )}
          </DialogContent>
        </div>
      </div>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {video_id && (
            <Box
              display="flex"
              sx={{ padding: 1 }}
              className={classes.layoutToggle}
              justifyContent="flex-end"
            >
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                size="small"
              >
                <ToggleButton
                  style={{ padding: "2px" }}
                  value="column"
                  aria-label="left aligned"
                >
                  <HorizontalSplitIcon />
                </ToggleButton>
                <ToggleButton
                  style={{ padding: "2px" }}
                  value="row"
                  aria-label="centered"
                >
                  <VerticalSplitIcon />
                </ToggleButton>
                <ToggleButton
                  style={{ padding: "2px" }}
                  value="no-video"
                  aria-label="right aligned"
                >
                  <VideocamOffIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}
        </div>
        <div>
          <Button variant="text" onClick={handleClose} color="primary">
            Cancel
          </Button>
          {props.summary ? (
            nextButton()
          ) : (
            <Button
              onClick={userMark ? updateMark : saveMark}
              color="primary"
              variant="contained"
              disabled={props.isLoading}
              autoFocus
            >
              Save
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

Terminal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default Terminal;
